import React from 'react'
import Image from '@image'
import Video from '../Video'
import Dialog from '@commerce-ui/core2/Dialog'
import IconClose from '../icons/IconClose'

import ProductComponent from './ProductComponent'
import styledBox from '../../styledBox'
import putImageSizes from '../../helpers/putImageSizes'
import { styled } from '../../components/stitches/stitches'

const DialogHeader = styled('div', {
  boxSizing: 'border-box',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1,
  pointerEvents: 'none',
  width: '100%',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  containerMargin: 'px'
})

const DialogBody = styled('div', {
  display: 'grid',
  position: 'relative',
  overflow: 'visible',
  gridTemplateColumns: 'unset',
  gridTemplateRows: '1fr auto',
  minHeight: 'calc(71vh + 110px)',
  maxHeight: '100%',
  '@sm': {
    minHeight: '100vh',
    maxHeight: 'none'
  },
  '@lg': {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'unset'
  },
  '@xxl': {
    gridTemplateColumns: '1fr 800px'
  }
})

const DialogBodyImage = styled('div', {
  position: 'relative',
  variants: {
    isEmpty: {
      true: {
        display: 'none',
        '@lg': {
          display: 'block'
        }
      }
    }
  }
})

//TODO stitches:
const DialogBodyImageInner = styledBox(
  {
    position: ['relative', null, null, 'sticky'],
    minHeight: 100,
    top: 0,
    left: 0,
    width: '100%',
    height: ['100%', null, null, '100vh'],
    py: ['s8', 's9', 's10', 'containerMargin'],
    px: ['s11', 's11', 's11', 'containerMargin']
  },
  { fitW: true, fitH: true }
)

const DialogBodyProducts = styled('div', {
  gridRowGap: '$2',
  gridColumnGap: '$8',
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  overflowY: 'hidden',
  overflowX: 'auto',
  alignContent: 'center',
  justifyItems: 'center',
  pb: '$7',
  '&::-webkit-scrollbar': { display: 'none' },
  '&::before': {
    content: "''",
    display: 'block',
    width: '0.1px'
  },
  '&::after': {
    content: "' '",
    display: 'block',
    width: '0.1px'
  },
  '@lg': {
    gridColumnGap: 'unset',
    gridAutoColumns: 'unset',
    gridAutoFlow: 'unset',
    overflowX: 'visible',
    justifyItems: 'unset',
    bg: '$cream',
    pb: 0
  }
})

const ProductWrapper = styled('div', {
  position: 'relative',
  width: '240px',
  '@lg': {
    width: 'auto'
  },
  variants: {
    appearance: {
      large: {
        width: '280px',
        '@sm': {
          width: '340px',
        },
        '@md': {
          width: '380px',
        },
        '@lg': {
          width: 'auto'
        },
      }
    }
  }
})

const CloseButton = styled('div', {
  pointerEvents: 'all',
  cursor: 'pointer',
  padding: '$5'
})

const DialogWithProducts = ({
  image,
  video,
  products,
  isOpen,
  onClose,
  withImagePreview = true,
  setOpen = () => {}
}) => {
  return (
    <Dialog
      backgroundColor={'rgba(0,0,0,0.7)'}
      width={'100%'}
      height={'100%'}
      isOpen={isOpen}
      placement={['bottom', null, null, 'center']}
      anchoredTo={'window'}
      onClose={() => {
        setOpen(false)
        if (onClose) {
          onClose()
        }
      }}
      root={{
        bg: withImagePreview ? ['white', null, null, 'cream400'] : ['white', null, null, 'rgba(0,0,0,0)'],
        position: 'relative'
      }}
      container={{
        height: '100%',
        position: 'relative'
      }}
    >
      <DialogHeader>
        <CloseButton
          onClick={() => {
            setOpen(false)
            if (onClose) {
              onClose()
            }
          }}
        >
          <IconClose size={20} />
        </CloseButton>
      </DialogHeader>
      <DialogBody>
        <DialogBodyImage
          isEmpty={!withImagePreview}
          onClick={() => {
            if (!withImagePreview) {
              setOpen(false)
              if (onClose) {
                onClose()
              }
            }
          }}
        >
          {withImagePreview && (
            <DialogBodyImageInner>
              {video ? (
                <Video video={video} objectFit={'contain'} />
              ) : (
                <Image
                  image={image}
                  sizes={putImageSizes(['90vw', null, null, '50vw'])}
                  objectFit={'contain'}
                  ignoreBottomPadding
                />
              )}
            </DialogBodyImageInner>
          )}
        </DialogBodyImage>

        <DialogBodyProducts>
          {products &&
            products.map(
              (product, i) =>
                product && (
                  <ProductWrapper key={i} appearance={!withImagePreview ? 'large' : 'default'}>
                    <ProductComponent product={product} key={i} />
                  </ProductWrapper>
                )
            )}
        </DialogBodyProducts>
      </DialogBody>
    </Dialog>
  )
}
DialogWithProducts.defaultProps = {}

DialogWithProducts.propTypes = {}
export default DialogWithProducts
