import React from 'react'
import Box from "@commerce-ui/core2/Box";
import Button from "@button";
import Image from "@image";

import PropTypes from 'prop-types';
import putImageSizes from "../../helpers/putImageSizes";
import styledBox from "../../styledBox";

const CardTextBox = styledBox({
  py: 's7',
  ml: props => props.hasLeftIndent ? 's7' : 0,
  font: 'heading03'
})


const Card = ({text, image, href, hasLeftIndent}) => {

  return <div>
    <Button href={href} sx={{display: 'block'}}>
      <Image
        image={image}
        sizes={putImageSizes(['80vw','80vw','34vw'])}
      />
      <CardTextBox hasLeftIndent={hasLeftIndent} dangerouslySetInnerHTML={{__html: text}} />
    </Button>
  </div>
};
const Wrap = styledBox({
  width: '100%',
  overflowX: 'auto',
  WebkitOverflowScrolling : 'touch',
  "::-webkit-scrollbar": {display: 'none'}
})
const Inner = styledBox({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: [
    'repeat(3, 80vw)',
    'repeat(3, 80vw)',
    'repeat(3, 1fr)',
    'repeat(3, 1fr)',
    'repeat(3, 1fr)',
  ]
})

const ThreeCards = ({ items }) => {

  return <Wrap>
    <Inner>
      {
        items.map((item, i) => <Card {...item} key={i} hasLeftIndent={i === 0} />)
      }
    </Inner>
  </Wrap>
};

ThreeCards.propTypes = {
  items: PropTypes.array.isRequired
};

export default ThreeCards
