import React from 'react'
import Container from '@commerce-ui/core2/Container'
import Logo from '../Logo'

import { styled } from '../../components/stitches/stitches'

const BigLogoWrapper = styled('div', {
  position: 'absolute',
  left: 0,
  top: '100vh',
  width: '100%',
  pointerEvents: 'none',
  display: 'none',
  zIndex: 2,
  color: 'white',
  transition: 'opacity 300ms',
  svg: {
    width: '100%'
  },
  '@lg': {
    display: 'block'
  }
})

const Wrapper2 = styled('div', {
  position: 'absolute',
  bottom: 0,
  pb: '$11',
  width: '100%'
})

const BigLogo = ({ isHidden, isWhite }) => {
  return (
    <BigLogoWrapper isHidden={isHidden} isWhite={isWhite} id={'BigLogo'}>
      <Wrapper2>
        <Container>
          <Logo />
        </Container>
      </Wrapper2>
    </BigLogoWrapper>
  )
}

export default BigLogo
