import React, { useState, useRef, useEffect } from 'react'
import Image from '@image'
import Video from '../Video'

import DialogWithProducts from './DialogWithProducts'

import { hideGlobalCursor, moveGlobalCursor, showGlobalCursor } from '../../helpers/globalCursor'
import { styled } from '../../components/stitches/stitches'

const Button = styled('div', {
  width: '100%',
  position: 'relative',
  display: 'block',
  cursor: 'pointer'
})

const ImageWithProducts = ({
  image,
  video,
  products,
  objectFit,
  isOpen = false,
  onClose,
  imageSizes,
  eagerLoad,
  withImagePreview = true
}) => {
  const [open, setOpen] = useState(isOpen)
  const ref = useRef(null)

  useEffect(() => {
    return () => {
      hideGlobalCursor()
    }
  })

  useEffect(() => {
    setOpen(isOpen)
    return () => {
      setOpen(false)
    }
  }, [isOpen])

  const videoElem = <Video video={video} />

  const imageElem = (
    <Image
      image={image}
      sizes={imageSizes ? imageSizes : '100vw'}
      objectFit={objectFit}
      loading={eagerLoad ? 'eager' : null}
    />
  )

  return (image || video) && products && products.length > 0 ? (
    <>
      <Button
        onClick={() => setOpen(true)}
        onMouseMove={() => {
          moveGlobalCursor()
        }}
        onMouseEnter={() => {
          showGlobalCursor('Shop')
        }}
        onMouseLeave={() => {
          hideGlobalCursor()
        }}
        ref={ref}
      >
        {video ? videoElem : imageElem}
      </Button>
      <DialogWithProducts
        image={image}
        video={video}
        products={products}
        onClose={onClose}
        withImagePreview={withImagePreview}
        isOpen={open}
        setOpen={setOpen}
      />
    </>
  ) : video ? (
    videoElem
  ) : (
    imageElem
  )
}
ImageWithProducts.defaultProps = {}

ImageWithProducts.propTypes = {}
export default ImageWithProducts
