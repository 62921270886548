import React, {useState} from 'react'
import {lin} from "@commerce-ui/core2";
import Link from "@link";
import Image from "@image";
import Dialog from "@commerce-ui/core2/Dialog";
import AddToBagButton from "../AddToBagButton"
import ProductPrice from "../ProductPrice"
import SelectableRow from "../SelectableRow";
import putImageSizes from "../../helpers/putImageSizes";
import useCartModifier from "@data/useCartModifier";
import {useNotificationSystem} from "@commerce-ui/core2/NotificationSystem";
import SelectInline from "@commerce-ui/core2/SelectInline";
import styledBox from "../../styledBox";
import BuyNotification from "../ProductDetails/BuyNotification"
import AlertNotification from "../ProductDetails/AlertNotification";
import Analytics from "../../helpers/analytics";

import DialogHeader from "../DialogHeader";
import Container from "@commerce-ui/core2/Container";
import NotifyForm, {JoinTheWaitlistButton} from "../NotifyForm"

const Root = styledBox({
  display: 'grid',
  gridTemplateColumns: [
    'unset',
    null,
    null,
    '1fr 1fr',
  ],
});
const Meta = styledBox({
  alignSelf: 'center',
  p: lin('s9','s10'),
  minWidth: 300,
  display: [
    'none',
    null,
    null,
    'block'
  ]
})
const Title = styledBox({ mb: 's2', font: 'heading08' })
const PriceBox = styledBox({ font: 'heading01', color: 'mono600',lineHeight: 1 })
const Spacer = styledBox({mb: 's8'});
const ViewDetails = styledBox({width: '100%', textAlign: 'center', mt: 's7', textDecoration: 'underline', font: 'body02'})

const ImageBox = styledBox({position: 'relative'})
const ImageOverlay = styledBox({
  display: [
    'grid',
    null,
    null,
    'none'
  ],
  width: '100%',
  gridTemplateColumns: ['1fr', null, '1fr 100px'],
  gridColumnGap: 's5',
  gridRowGap: 's6',
  pt: 's7',
})
const Box1 = styledBox({
  bg: '#F5F2ED',
  paddingBottom: '140.84507042253523%',
  position: 'relative',
  width: '100%',
})
const Box2 = styledBox({
  color: 'mono500',
  font: 'heading01',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0, left: 0, width: '100%', height: '100%'
})
const noImageElem = <Box1>
  <Box2>No image</Box2>
</Box1>
const ImageOverlayText = styledBox({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
})

const SoldOutBox = styledBox({
  font: 'heading09',
  color: 'mono600',
  textAlign: 'center'
})
const SoldOut = () => <SoldOutBox>Unfortunately this item is sold out</SoldOutBox>;
const ComingSoon = () => <SoldOutBox>Coming Soon</SoldOutBox>


//     <Link href={'/product/' + product.handle} sx={{font: 'heading06', lineHeight: 1,  textOverflow: 'ellipsis', height: '1.5em', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
// {product.title}
// </Link>

const TitleLink = Link.styled({
  sx: {
    font: 'heading06',
    lineHeight: 1,
    textOverflow: 'ellipsis',
    height: '1.5em',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
});

const ProductComponent = ({product}) => {

  if(!product) {return null}

  const cartModifier = useCartModifier();
  const notificationSystem = useNotificationSystem();
  const [waitlistDialogOpen, setWaitlistDialogOpen] = useState(false);
  const [selectedSizeVariant, setSelectedSizeVariant] = useState(null);

  const addToBagVariant = variant => {
    cartModifier.add(variant, [])
      .then( (response) => {
        if (response.isPartiallyUpdated) {
          showAlertNotification("We're sorry, but unfortunately we can't add this product to the bag. It was either just bought by another customer or you already have maximum available quantity in the bag.")
        }
        else {
          showNotificationOnAddToBag(response.variant)
          Analytics.addToBag(response.variant, 1, "popup");
        }

      } )
  };

  const showAlertNotification = (message) => {
    notificationSystem.show({
      content: ({ close }) => (
        <AlertNotification message={message} onClose={close} />
      ),
      placement: "topRight",
      timeout: 8000,
    });

    // Analytics
  };

  const showNotificationOnAddToBag = (lineItem) => {
    // console.log('show', lineItem);
    notificationSystem.show({
      content: ({ close }) => (
        <BuyNotification onClose={close} lineItem={lineItem}/>
      ),
      placement: "topRight",
      timeout: 5000,
    });
  };

  // let isSoldOut = product.variants.filter(v => v.available === true).length === 0
  let isPreorder = product.tags.includes('preorder')
  // let isPreorder = true

  const addToBagElement = product.variants.length > 1 ?

    <Dialog
      width={({ anchorRect }) => [ '100%', null, null, anchorRect.width ]}
      anchoredTo={["window", null, null, "button"]}
      maxHeight={['60vh', null, null, '100vh']}
      placement={['bottom']}
      button={
        <AddToBagButton
          disabled={cartModifier && cartModifier.isProcessing}
          isShort
          isPreorder={isPreorder}
        />
      }
      root={{
        boxShadow: t => t.boxShadow.default
      }}
    >
      {
        ({close})=>
          <SelectInline
            options={product.variants.map(v => ({
              ...v.size,
              // disabled: !v.available,
              // isLowStock: v.isLowStock,
              quantityAvailable: v.quantityAvailable,
              available: v.available,
              // option: product.variants.find(e => e.size.id === v.size.id)
            }))}
            allowEmpty={true}
            onChange={ val => {
              if (val.quantityAvailable === 0) {
                setSelectedSizeVariant(product.variants.find(e => e.size.id === val.id))
                setWaitlistDialogOpen(true);
              } else {
                addToBagVariant(product.variants.find(e => e.size.id === val.id));
              }
              close();
            }}
          >
            <SelectableRow />
          </SelectInline>
      }
    </Dialog>

    :
      product.variants[0].quantityAvailable === 0 ?
        <JoinTheWaitlistButton
          onClick={() => {
            setWaitlistDialogOpen(true);
            setSelectedSizeVariant(product.variants[0])
          }}
        />
        :
        <AddToBagButton
          disabled={cartModifier && cartModifier.isProcessing}
          onClick={() => addToBagVariant(product.variants[0]) }
          isShort
          isPreorder={isPreorder}
        />;


  return <Root>

    <Meta>
      <Link href={'/product/' + product.handle}><Title>{product.title}</Title></Link>
      <PriceBox><ProductPrice product={product} /></PriceBox>
      <Spacer/>
      {
        product.tags.includes('coming-soon') ?
          <ComingSoon/>
          :
          addToBagElement
      }
      <ViewDetails>
        <Link href={'/product/' + product.handle}>
          View details
        </Link>
      </ViewDetails>
    </Meta>

    <ImageBox>

      {product.primaryImage ? (

        <Link href={'/product/' + product.handle}>
          <Image
            image={product.primaryImage}
            sizes={putImageSizes([
              '100vw',
              '100vw',
              '80vw',
              '25vw'
            ])}
          />
        </Link>
        ) : noImageElem
      }

      <ImageOverlay>
        <ImageOverlayText>
          <TitleLink href={'/product/' + product.handle}>
            {product.title}
          </TitleLink>
          <PriceBox><ProductPrice product={product} /></PriceBox>
        </ImageOverlayText>

        {
          product.tags.includes('coming-soon') ?
            <ComingSoon/>
            :
            addToBagElement
        }

      </ImageOverlay>

    </ImageBox>

    <Dialog
      width={'100%'}
      maxWidth={[null,null,null,420]}
      height={'auto'}
      placement={["bottom", null, null, "center"]}
      anchoredTo={"window"}
      isOpen={waitlistDialogOpen}
      onClose={()=>setWaitlistDialogOpen(false)}
      root={{
        height: '100%',
        bg: 'cream',
        pb: ['s11', null, null, 's10']
      }}
      header={<DialogHeader title={"Waitlist"} close={() => setWaitlistDialogOpen(false)}/>}
    >
      <Container>
        <NotifyForm
          variant={selectedSizeVariant}
          isOneSize={(product.variants.length === 1)}
          handle={product.handle}
          onDismiss={()=>{
            setWaitlistDialogOpen(false);
            setSelectedSizeVariant(null);
          }}
        />
      </Container>
    </Dialog>

  </Root>
}
export default ProductComponent
