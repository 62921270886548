import React from 'react'
import Container from "@commerce-ui/core2/Container";
import styledBox from "../../styledBox";

const Wrapper = styledBox({
    position: 'relative',
    maxWidth: '600px',
    margin: '0 auto',
    textAlign: 'center',
    font:'heading13'
  })

const JournalText = ({ text }) => {

  return <Container>
    <Wrapper dangerouslySetInnerHTML={{__html: text}} />
  </Container>
};

JournalText.defaultProps = {
};

JournalText.propTypes = {
};
export default JournalText
