import React from 'react'
import ImageWithProducts from "../ImageWithProducts"
import styledBox from "../../styledBox";

const Root = styledBox({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: [
    '1fr',
    null,
    null,
    'repeat(12,1fr)'
  ],
  alignItems: 'end'
});
const Image1 = styledBox({
  gridColumn: props => [
    null,
    null,
    null,
    props.isSmall ? '1 / 5' : '1 / 7'
  ],
  p: props => [
    props.isSmall ? 's11' : 0,
    null,
    null,
    0
  ],
  pt: 0
})

const Image2 = styledBox({
  gridColumn: props => [
    null,
    null,
    null,
   props.isSmall ? '9 / 13' : '7 / 13'
  ],
  p: props => [
    props.isSmall ? 's11' : 0,
    null,
    null,
    0
  ],
  pb: 0
})

const JournalTwoImages = ({ image1, image2 }) => {

  return  <Root>

      <Image1 isSmall={image1.isSmall}>
        <ImageWithProducts
          {...image1}
        />
      </Image1>

      <Image2 isSmall={image2.isSmall}>
        <div>
          <ImageWithProducts
            {...image2}
          />
        </div>
      </Image2>

    </Root>
};

JournalTwoImages.defaultProps = {
}

JournalTwoImages.propTypes = {
};
export default JournalTwoImages