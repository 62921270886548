import React, { useEffect, useState } from 'react'
import { styled } from '../../components/stitches/stitches'

const Root = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridColumnGap: 'GRIDGAP'
})

const Inner = styled('div', {
  position: 'relative',
  gridColumn: 'span 12',
  '@lg': {
    gridColumn: '3 / 11'
  },
  '@xl': {
    gridColumn: '2 / 12'
  }
})

const RatioWrapper = styled('div', {
  paddingBottom: (9 / 16) * 100 + '%',
  iframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  }
})

const JournalVimeoVideo = ({ videoId }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])
  return (
    <Root>
      <Inner>
        <RatioWrapper>
          {loaded && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: `
              <iframe
                src="https://player.vimeo.com/video/${videoId}?byline=false&autoplay=true&muted=true&loop=true&playsinline=true&sidedock=0"
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen />
             `
                }}
              />
            </>
          )}
        </RatioWrapper>
      </Inner>
    </Root>
  )
}

JournalVimeoVideo.defaultProps = {}

JournalVimeoVideo.propTypes = {}
export default JournalVimeoVideo
