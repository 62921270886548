import React from 'react'

import Banner from "./Banner";
import ThreeCards from "./ThreeCards";
import JournalText from "./JournalText";
import JournalIntro from "./JournalIntro";
import JournalImage from "./JournalImage";
import JournalTwoImages from "./JournalTwoImages";
import BigLogo from "./BigLogo";
import JournalVimeoVideo from "./JournalVimeoVideo";


const SectionMaker = ({type, sectionProps}) => {

  if (type === "Banner")  {
    return <Banner {...sectionProps}/>
  }
  if (type === "ThreeCards")  {
    return <ThreeCards {...sectionProps}/>
  }
  if (type === "BigLogo")  {
    return <BigLogo {...sectionProps}/>
  }
  if (type === "JournalText") {
    return <JournalText {...sectionProps} />
  }
  if (type === "JournalIntro") {
    return <JournalIntro {...sectionProps} />
  }
  if (type === "JournalImage") {
    return <JournalImage {...sectionProps} />
  }
  if (type === "JournalTwoImages") {
    return <JournalTwoImages {...sectionProps} />
  }
  if (type === "VimeoVideo") {
    return <JournalVimeoVideo {...sectionProps} />
  }

  return <div>
    nothing returned
  </div>


};

export default SectionMaker