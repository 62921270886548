import React from 'react'
import Container from '@commerce-ui/core2/Container'
import styledBox from '../../styledBox'

const Wrapper = styledBox({
  position: 'relative',
  maxWidth: '300px',
  margin: '0 auto',
  textAlign: 'center',
  font: 'heading06'
})

const JournalIntro = ({ field1, field2, field3 }) => {
  return (
    <Container>
      <Wrapper>
        {field1}
        <br />
        <em>{field2}</em> <br />
        {field3}
      </Wrapper>
    </Container>
  )
}

JournalIntro.defaultProps = {}

JournalIntro.propTypes = {}
export default JournalIntro
