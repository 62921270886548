import React, {useState} from 'react'
import ImageWithProducts from "../ImageWithProducts"
import styledBox from "../../styledBox";

const Root = styledBox({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gridColumnGap:  'gridGap'
})
const Inner = styledBox({
  gridColumn: [
    'span 12',
    'span 12',
    'span 12',
    '3 / 11',
    '2 / 12',
    '2 / 12',
  ]
})

const JournalImage = ({ image, video, mobileImage, products }) => {
  return  <Root>
      <Inner>
        <ImageWithProducts image={image} video={video} mobileImage={mobileImage} products={products} />
      </Inner>
    </Root>
};

JournalImage.defaultProps = {
}

JournalImage.propTypes = {
};
export default JournalImage